import "./index.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Airtable from "airtable";
import { useEffect } from "react";

export default function App() {
  useEffect(()=>{
    const base = new Airtable({
      endpointUrl: `//${window.location.host}`,
      apiKey: 'empty',
    }).base("appSy1Lsmt7PYcyh0");

  base("table").select({
    maxRecords: 3,
    view: "Grid view",
    filterByFormula: 'FIND("مانس", {بحث}) > 0',
  })
  .eachPage((records, fetchNextPage)=> {
    records.forEach(record=> {
      console.log(record.fields);
      console.log('Retrieved', record.get('رقم الجلوس'));
    });
    fetchNextPage();

  }, err => {
    if (err) { console.error(err); return; }
  });

  }, []);
  

  
  return (
    <Router>
      <div>

        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
function Home() {
  return  <div dir="rtl" className="bg-blue-400 h-screen grid">
  <div className="w-3/4 my-auto mr-20">
    <h1 className="text-5xl font-bold mb-10 text-white">مرحبا</h1>
    <p className="text-white">
      هنا نتائج الكليات الأزهرية

      
    </p>
  </div>
</div>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

